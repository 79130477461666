import React, { useContext, useEffect } from "react"
import tw from "twin.macro"

// Components
import { Link } from "gatsby"
import Layout from "@components/layout"
import Seo from "@components/seo"
import Section from "@components/section"
import Container from "@components/container"
import { Heading2 } from "@styles/ui"
import { AppContext } from "@components/context"
import PrimaryCta from "@components/PrimaryCta"
import { isBrowser } from "@components/utils"

// Assets
import xywavColourfulLogoImg from "@images/logos/xywav-logo.svg"

const IHThankYouPage = ({ location }) => {
  const context = useContext(AppContext)

  useEffect(() => {
    // Screenshots
    if (isBrowser) {
      const isEDMode = window.location.href.indexOf("ed=1") > -1
      if (isEDMode && window.location.search.indexOf("altThankYouMsg") > -1) {
        context.setAltThankYouMsg(true)
      }
    }
  }, [])

  return (
    <Layout location={location} headerType="ih" footerType="ih">
      {" "}
      <Seo
        title="IH Subscribe Thank You - XYWAV"
        description="Subscribe confirmation for XYWAV™ (calcium, magnesium, potassium, and sodium oxybates) oral solution updates. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <Section noHero bgColour={tw`bg-lilac`}>
        <img
          src={xywavColourfulLogoImg}
          alt=""
          tw="hidden z-[1] absolute top-[60px] right-[-40px] xl:(block top-[60px] right-[-104px] w-[580px])"
        />
        <Container addedStyles={tw`xl:pb-28`}>
          <div
            css={[
              tw`pt-4 xl:(pt-0 pb-20)`,
              context.altThankYouMsg && tw`xl:(pb-0)`,
            ]}
          >
            <h1
              css={[
                Heading2,
                tw`text-[32px] leading-[36.8px]`,
                tw` md:(leading-[100%])`,
                tw` xl:(text-6xl leading-[1.15] pb-2)`,
              ]}
            >
              Thank you for <br tw="hidden xl:block" />
              signing&nbsp;up!
            </h1>
            <div tw="z-[1] relative mt-8 md:(mr-[120px]) xl:(mr-0 mt-5 max-w-[730px])">
              <p tw="pb-8 lg:(text-xl)">
                Keep an eye out for helpful information about
                Idiopathic&nbsp;Hypersomnia&nbsp;(IH).
              </p>
              {context.altThankYouMsg && (
                <>
                  <p tw="lg:text-xl">
                    While you&#39;re waiting for your XYWAV prescription to
                    arrive, be sure to enroll in JazzCares<sup>&reg;</sup>. You
                    can get treatment support like a JazzCares Nurse Case
                    Manager, and a pharmacist 24/7 as well as financial
                    assistance options. This and more can be available to you
                    right from the start of your treatment journey.
                  </p>
                  <div tw="py-9">
                    <PrimaryCta
                      isExternal
                      url="https://www.jazzcares.com/patients/xywav/"
                    >
                      Enroll in JazzCares
                    </PrimaryCta>
                  </div>
                </>
              )}
              <Link
                to="/idiopathic-hypersomnia/"
                css={[
                  tw`text-studio underline text-xl block text-left hover:no-underline md:text-left`,
                  context.altThankYouMsg && tw`text-center`,
                ]}
              >
                Return Home
              </Link>
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default IHThankYouPage
